<template>
  <div>
    <v-card-text>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        solo
        clearable
        :items="allStates"
        v-model="state_filter"
        item-text="state_name"
        item-value="id"
        label="State*"
        required
        :return-object="true"
        :rules="[rules.required('State')]"
        @change="loadDistricts(state_filter.id, 'Active')"
      ></v-autocomplete>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        solo
        clearable
        :items="allDistricts"
        v-model="district_filter"
        item-text="district_name"
        item-value="id"
        label="District*"
        required
        :return-object="true"
        :rules="[rules.required('District')]"
        @change="loadMandals(district_filter.id, 'Active')"
      ></v-autocomplete>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        solo
        clearable
        :items="allMandals"
        v-model="mandal_filter"
        item-text="mandal_name"
        item-value="id"
        label="Mandal*"
        required
        :return-object="true"
        :rules="[rules.required('Mandal')]"
        @change="loadVillages(mandal_filter.id, 'Active')"
      ></v-autocomplete>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        solo
        clearable
        :items="allVillages"
        v-model="village_filter"
        item-text="village_name"
        item-value="id"
        label="Village*"
        required
        :return-object="true"
        :rules="[rules.required('Village')]"
        @change="loadMPconstituencies(state_filter.id, 'Active')"
      ></v-autocomplete>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        solo
        clearable
        :items="allMpConstituencies"
        v-model="mpcons_filter"
        item-text="mp_constituency_name"
        item-value="id"
        label="MP constituency*"
        required
        :rules="[rules.required('MP constituency')]"
        @change="loadMLAconstituencies(state_filter.id, 'Active')"
      ></v-autocomplete>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        solo
        clearable
        :items="allMlaConstituencies"
        v-model="mlacons_filter"
        item-text="mla_constituency_name"
        item-value="id"
        label="MLA constituency*"
        required
        :rules="[rules.required('MLA constituency')]"
        @change="loadWards(village_filter.id, 'Active')"
      ></v-autocomplete>
      <v-card flat class="mt-2 mb-2">
        <v-row dense>
          <v-col class="mx-4" cols="5">
            <v-text-field
              v-model.number="numberOfWards"
              label="Enter Number of Wards to Increment"
              type="number"
              hint="Please don`t enter ward name just enter no of wards count to generate the wards"
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-btn @click="postData" class="mt-3 primary">Submit</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn @click="dialog = true" class="mt-3 primary">Bulk Add</v-btn>
            <!-- <a href="/andhra.csv" download="samplefile.csv">
              <button type="button">Sample</button>
            </a> -->
          </v-col>
        </v-row>
      </v-card>
      <v-data-table
        v-model="selectedWards"
        :headers="headers"
        :items="allWards"
        fixed-header
        height="400px"
        :loading="tableLoading"
        loading-text="Loading... Please wait"
        dense
        item-key="id"
        :search="search"
        show-select
      >
        <template v-slot:top>
          <v-toolbar flat dense>
            <v-toolbar-title>Wards</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn
              color="error"
              outlined
              :loading="isLoading"
              v-if="selectedWards.length > 0"
              @click="deleteSelectedHandler"
              >Delete Selected</v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              dense
              style="max-width: 300px"
            ></v-text-field>
            <v-chip class="ma-2" color="green" text-color="white">
              Total
              <v-avatar right class="green darken-4">
                {{ allWards.length }}
              </v-avatar>
            </v-chip>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark v-bind="attrs" v-on="on" text color="indigo">
                  {{ selectedStatus }} <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="status in ['Active', 'Deleted']"
                  :key="status"
                  @click="changeStatusFilter(status)"
                  dense
                >
                  <v-list-item-title>{{ status }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getStatusColor(item.status)"
            dark
            small
            @click="
              changeStatus(
                'ward_code',
                item.ward_code,
                item.status,
                village_filter
              )
            "
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.village_name="{ item }">
          {{ item.village_id ? village_filter.village_name : "" }}
        </template>
        <template v-slot:item.mandal_name="{ item }">
          {{ item.village_id ? mandal_filter.mandal_name : "" }}
        </template>
        <template v-slot:item.district_name="{ item }">
          {{ item.village_id ? district_filter.district_name : "" }}
        </template>
        <template v-slot:item.state_name="{ item }">
          {{ item.village_id ? state_filter.state_name : "" }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add wards</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row dense>
                <v-col cols="12">
                  <v-autocomplete
                    dense
                    :items="allStates"
                    v-model="state"
                    item-text="state_name"
                    item-value="id"
                    label="State*"
                    required
                    :return-object="true"
                    :rules="[rules.required('State')]"
                    @change="loadDistricts(state.id)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="10">
                  <p>Download sample file and fill detals & uplod it</p>
                </v-col>
                <v-col cols="2">
                  <a href="/andhra.csv" download="samplefile.csv">
                    <v-btn icon>
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </a>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="file"
                    chips
                    label="Upoload file"
                    accept=".csv"
                    required
                    :rules="[rules.required('File')]"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog"> Close </v-btn>
          <v-btn :loading="isUploading" color="blue darken-1" text @click="uploadFileHandler">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import rules from "../helpers/validation";
import axios from "axios";
import { baseURL } from "../constant/constant";
// import { HollowDotsSpinner } from "epic-spinners";

export default {
  name: "Home",
  components: {
    // HollowDotsSpinner,
  },
  data: () => ({
    selection: null,
    numberOfWards: 0,
    newWardName: null,
    selectedStatus: "Active",
    moment: moment,
    file: null,
    search: "",
    state_filter: {
      state_name: "",
      state_code: "",
    },
    district_filter: "",
    mpcons_filter: "",
    mlacons_filter: "",
    mandal_filter: "",
    village_filter: "",
    rules: rules,
    tableLoading: false,
    dialog: false,
    valid: true,
    state: "",
    district: "",
    mpconstituency: "",
    mlaconstituency: "",
    mandal: "",
    village: "",
    wards: "",
    selectedWards:[],
    isLoading:false,
    isUploading:false,
    headers: [
      {
        text: "Sno",
        value: "index",
        sortable: false,
      },
      {
        text: "Ward",
        value: "ward_name",
      },
      {
        text: "Village",
        value: "village_name",
      },
      {
        text: "Mandal",
        value: "mandal_name",
      },
      {
        text: "District Name",
        value: "district_name",
      },
      {
        text: "State Name",
        value: "state_name",
      },
      {
        text: "Status",
        value: "status",
      },
    ],
  }),
  computed: {
    ...mapGetters([
      "allStates",
      "alertType",
      "alertMessage",
      "allDistricts",
      "allMpConstituencies",
      "allMlaConstituencies",
      "allMandals",
      "allVillages",
      "allWards",
    ]),
    ...mapState(["response"]),
  },
  mounted() {
    this.$store.commit("set_wards", []);

    this.$store.dispatch("fetchStates");
  },
  methods: {
    ...mapActions([
      "showAlert",
      "fetchStates",
      "fetchDistricts",
      "fetchMPConstituencies",
      "fetchgetMLAConstituencies",
      "fetchMandals",
      "fetchVillages",
      "fetchWards",
      "addNewWards",
    ]),
    async postData() {
      // console.log(this.numberOfWards);
      const payload = {
        village_id: this.village_filter.id,
        no_of_wards: this.numberOfWards,
      };
      console.log(payload);
      await this.addNewWards(payload);

      if (this.village_filter) {
        await this.loadWards(this.village_filter, this.selectedStatus);
      }
      // this.closeDialog();
    },
    async changeStatusFilter(status) {
      this.selectedStatus = status;
      await this.loadWards(this.village_filter.id, this.selectedStatus);
    },
    uploadFileHandler() {
      this.isUploading=true;
      let formData = new FormData();
      formData.append("state", this.state);
      formData.append("state_limit", 1000);
      formData.append("file", this.file);
      axios
        .post(`http://localhost:3000/admin/csv`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("res", res);
          this.isUploading=false;
          if(res && res.data && res.data.status=='success'){
            this.$store.dispatch("showAlert", {
            type: "success",
            message: `Uploaded Successfully!`,
          });
          }
        })
        .catch((err) => {
          this.isUploading=false;
          this.$store.dispatch("showAlert", {
            type: "error",
            message: `${err}`,
          });
          console.log(err);
        });
    },
    deleteSelectedHandler() {
      this.isLoading = true;
      axios
        .delete(`${baseURL}admin/wards`, {
          data: {
            ids: this.selectedWards.map((v) => v.id),
          },
        })
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch("showAlert", {
            type: "success",
            message: `Deleted Successfully!`,
          });
          this.selectedWards = [];
          if (this.village_filter) {
            this.loadWards(this.village_filter.id, 'Active')
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$store.dispatch("showAlert", {
            type: "error",
            message: `${err}`,
          });
        });
    },
  },
};
</script>
<style scoped>
.custom-badge {
  background-color: darksalmon;
}
</style>
